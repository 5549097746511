module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.d-conproducts.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"excludes":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"]},
    },{
      plugin: require('../../../node_modules/@phx-husky/gatsby-plugin-static-search/gatsby-browser.js'),
      options: {"plugins":[],"queries":[{"query":"{\n  allProductCategory {\n    nodes {\n      id\n      name\n      seoMetaKeywords\n      url\n      imageDescription\n    }\n  }\n}\n","indexName":"dcon","settings":{"searchableAttributes":["name","description","keywords"]}},{"query":"{\n  allArticle {\n    nodes {\n      name\n      url\n      id\n      seoMetaKeywords\n      seoMetaDescription\n      numericList {\n        properties {\n          description\n          items {\n            properties {\n              title\n              description\n            }\n          }\n        }\n      }\n    }\n  }\n}","indexName":"dcon","settings":{"searchableAttributes":["name","description","keywords"]}},{"query":"{\n  allProductPage {\n    nodes {\n      description\n      productName\n      id\n      seoMetaKeywords\n      url\n    }\n  }\n}","indexName":"dcon","settings":{"searchableAttributes":["name","description","keywords"]}},{"query":"{\n  faqPage {\n    faqCategories {\n      value\n    }\n    faq {\n      properties {\n        title\n        subtitle\n        faqItems {\n          properties {\n            answer\n            question\n          }\n        }\n        buttonRedirect {\n          name\n          url\n        }\n      }\n    }\n    id\n    link\n    name\n    seoMetaKeywords\n  }\n}","indexName":"dcon","settings":{"searchableAttributes":["name","description","keywords"]}},{"query":"{\n  allLds {\n    nodes {\n      id\n      body\n      title\n      path\n    }\n  }\n}","indexName":"dcon","settings":{"searchableAttributes":["name","description","keywords"]}}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K77QD3T","includeInDevelopment":false,"timeout":3000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dcon","short_name":"Dcon","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/vsts/work/1/s/packages/gatsby-theme-husky/static/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5496a806d520299161b46edd0501b26d"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"duration":500,"offset":-100,"stripHash":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"/home/vsts/work/1/s/packages/gatsby-theme-husky/gatsby","projectRoot":"/home/vsts/work/1/s/packages/gatsby-theme-husky","babel":false,"tsNode":true},
    }]
